import {useState} from 'react'
import {authService} from './services/AuthService'
import {tokenService} from './services/TokenService'

const Login = ({onLogin}) => {

  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');

  const handleSubmit = (ev) => {
    ev.preventDefault();
    authService.login(login, pass).then((data) => {
      tokenService.saveToken(data.token);
      onLogin();
    });
    return false;
  }

  return (
    <form onSubmit={(ev) => handleSubmit(ev)} className="login-box">
      <label htmlFor="login">login:</label>
      <input id="login" type="text" name="login" onChange={ev => setLogin(ev.target.value)} />
      <label htmlFor="pass">hasło:</label>
      <input id="pass" type="password" name="pass" onChange={ev => setPass(ev.target.value)} />
      <button type="submit" className="login-btn">zaloguj się</button>
    </form>
  );
}

export default Login;
