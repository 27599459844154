import './App.css';
import Login from './Login';
import { useEffect, useState } from 'react'
import { dataService } from './services/DataService'
import { tokenService } from './services/TokenService'

function App() {

  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState<any>([]);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  useEffect(() => {
    setIsAuth(!!tokenService.getToken());
    setIsLoading(true);
    if (!!tokenService.getToken()) {
      dataService.getChanges().then((data) => {
        setIsLoading(false);
        setData(data);
      }).catch(() => {
        tokenService.removeToken();
      });
    }
  }, [refresh]);

  const showInfo = (item) => {
    setSelectedItem(item);
    setIsInfoOpen(true);
  };

  const getList = (items) => {
    return <div className={'item-list space'}>
      {items && items.length > 0 && items.map((item, index) => <div
        key={`items-${index}`} className={'item'} onClick={() => showInfo(item)}>
        {item.room?.short_name}{' '}
        <span>({item.adults_number},{item.kids_number},{item.infant_number})</span></div>)}
      {isLoading ? <div className={'loading'}>Ładowanie danych...</div> : ''}
      {items && items.length == 0 ? <div className={'empty'}>brak</div> : ''}
    </div>
  }

  return !isAuth ? <Login onLogin={() => setRefresh(refresh + 1)}/> : <div className={'info'}>
    <div className={'small-info'}>Dzisiejsza data: {data.today}</div>
    <div className={'flex'}>
      <div>
        <strong className={'title'}>Wyjazdy dzisiaj:</strong>
        {getList(data.departuresToday)}
      </div>
      <div>
        <strong className={'title'}>Przyjazdy dzisiaj:</strong>
        {getList(data.arrivalsToday)}
      </div>
    </div>

    <div className={'flex'}>
      <div>
        <strong className={'title'}>Wyjazdy jutro:</strong>
        {getList(data.departuresTomorrow)}
      </div>
      <div>
        <strong className={'title'}>Przyjazdy jutro:</strong>
        {getList(data.arrivalsTomorrow)}
      </div>
    </div>

    <div className={'flex'}>
      <div>
        <strong className={'title'}>Wyjazdy wczoraj:</strong>
        {getList(data.departuresYesterday)}
      </div>
      <div>
        <strong className={'title'}>Przyjazdy wczoraj:</strong>
        {getList(data.arrivalsYesterday)}
      </div>
    </div>
    {isInfoOpen && selectedItem && <div className={'info-modal'}>
      <div className={'close-modal'} onClick={() => setIsInfoOpen(false)}>&times;</div>
      <table>
        <tr><td>Klient:</td><td className={'data-value'}>{selectedItem.client.name}</td></tr>
        <tr><td>Telefon:</td><td className={'data-value'}><a href={`tel:${selectedItem.client.phone}`}>{selectedItem.client.phone}</a></td></tr>
        <tr><td>Email:</td><td className={'data-value'}>{selectedItem.client.email}</td></tr>
        <tr><td>Pokój:</td><td className={'data-value'}>{selectedItem.room?.name}</td></tr>
        <tr><td>Przyjazd:</td><td className={'data-value'}>{selectedItem.date_from}</td></tr>
        <tr><td>Wyjazd:</td><td className={'data-value'}>{selectedItem.date_to}</td></tr>
        <tr><td colSpan={2} /></tr>
        <tr><td>Dorosłych:</td><td className={'data-value'}>{selectedItem.adults_number}</td></tr>
        <tr><td>Dzieci:</td><td className={'data-value'}>{selectedItem.kids_number}</td></tr>
        <tr><td>Niemowlaków:</td><td className={'data-value'}>{selectedItem.infant_number}</td></tr>
        <tr><td>Uwagi:</td><td className={'data-value'}>{selectedItem.reservation?.notes}</td></tr>
      </table>
    </div>}
  </div>;
}

export default App;
