class TokenService {
  storageKey = 'auth_token';
  getToken() {
    return localStorage.getItem(this.storageKey);
  }
  saveToken(token: string) {
    localStorage.setItem(this.storageKey, token);
  }
  removeToken() {
    localStorage.removeItem(this.storageKey);
  }
}

export const tokenService = new TokenService();